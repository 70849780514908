<template>
  <div>
    <page-title :title="`Bond Templates`">
      <template slot="button">
        <a
          href="#"
          @click.prevent="deleteTemplate"
          class="btn btn-danger float-right ml-2"
        ><i class="lni-trash mr-2"></i> Delete</a>
        <router-link
          :to="{ name: 'bond-templates.edit', params: { id: $route.params.id }}"
          class="btn btn-warning float-right"
        ><i class="lni-pencil mr-2"></i> Edit</router-link>
      </template>
    </page-title>
    <div class="row row-sm" v-if="is_loaded">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div id="-bond-template" v-html="template"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      template: {},
      is_loaded: false
    };
  },

  methods: {
    fetch() {
      this.is_loaded = false;
      this.$loader.start()

      this.$axios
        .get("/api/v1/dashboard/bond-templates/" + this.$route.params.id)
        .then(response => {
          this.template = response.data;
          this.is_loaded = true;
          this.$loader.stop()
        })
        .catch(error => {
          this.$alert.httpError(error.response);
        });
    },

    deleteTemplate() {
      this.$loader.start()
      this.$axios
        .delete("/api/v1/dashboard/bond-templates/" + this.$route.params.id)
        .then(() => {
          this.$loader.stop()
          this.$router.push({ name: "bond-templates.index" });
        });
    }
  }
};
</script>

<style lang="scss">
.tasks {
  text-align: right;

  a {
    margin: 0 10px;
  }
}
</style>